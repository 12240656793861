import crowgif from './bird_meitdev.gif';
import './App.css';

export default function App() {
  // this is just so none of the react stuff ends up loading
  if (true) {
    return (<></>);
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={ crowgif } className="App-logo" alt="logo" />
        
        <p>
          While this gets ready, why not checkout <a href="https://crowsnest.fandom.com/wiki/">the lore</a>?
        </p>
      </header>
    </div>
  );
}
